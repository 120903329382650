.App {
  min-height: 100vh;
  padding: 0px;
  font-family: 'Lato', sans-serif;
}

.footer {
  margin-top: auto;
}

.footer-site {
  background-color: #799fbd;
}

.footer-images {
  max-width: 150px;
}

.services-landing {
  background-image: url(assets/services-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #b1d287;
  height: 50vh;
}

.donate-page {
  background-image: url(assets/donate-bg.jpeg);
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  background-color: #b1d287;
  height: 100%;
}

.donate-content {
  background-color: rgba(255, 255, 255, 0.74);
}